<template>
  <button class="login-btn naver" v-on:click="clickLogInWithNaver">
    <img src="@/assets/img/login/naver_logo.svg" alt="" />
    Naver 계정으로 로그인
  </button>
</template>
<script>
export default {
  name: 'naverLogin',
  props: ['url', 'ru', 'cu', 'querys'],
  methods: {
    hasRequiredParams() {
      let required_params = ['url', 'ru', 'cu'];
      let is_valid = true;
      required_params.some((param) => {
        if (!this[param]) {
          is_valid = false;
          return true;
        }
      });
      return is_valid;
    },
    clickLogInWithNaver() {
      // 필수 파라미터 검증
      if (!this.hasRequiredParams()) {
        alert('필수 파라미터가 누락되었습니다.');
        return;
      }

      // 네이버 간편 로그인
      window.open(this.url, 'naver_login_popup', 'width=960, height=670, scrollbars=yes');
    },
    naverCallback(key, name, email) {
      let param_obj = {
        snsKey: key,
        snsName: name,
        snsEmail: email,
        ru: this.ru,
        cu: this.cu,
      };
      this.$axios
        .post('/user/login/naverSignInSuccessCallbackController.hs', null, {
          params: param_obj,
        })
        .then(function (response) {
          var result = response.data.result;

          if (result.REPL_CD == '000000') {
            window.location.href = result.redirectUrl;
          } else {
            alert(result.REPL_MSG);
          }
        });
    },
  },
  mounted() {
    // 네이버 콜백
    window.hsNaverCallback = (key, name, email) => {
      this.naverCallback(key, name, email);
    };
  },
};
</script>
