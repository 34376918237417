
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CommonVue extends Vue {
  // 공통 함수 정의
  COM = {
    ajax: {
      get: () => this["$axios"],
      call: (serviceUrl: string, paramObj: object = {}) => {
        return this.COM.ajax.get().post(serviceUrl, null, { params: paramObj });
      },
      // TODO 파일 ajax call 다르게 구현해야하는지?
    },
    router: {
      get: () => this["$router"],
    },
    route: {
      get: () => this["$route"],
      getQuery: (key: string) => this.COM.route.get().query[key] as string,
    },
    refs: {
      getElement: (refName: string) => this.$refs[refName] as HTMLElement,
      focus: (refName: string) => this.COM.refs.getElement(refName).focus(),
      getAttribute: (refName: string, attrName: string) =>
        this.COM.refs.getElement(refName).getAttribute(attrName),
    },
    filter: {
      object: (obj: object) => {
        console.log(obj);
      },
    },
    validate: {
      isValidEmail: (email: string) => {
        return new RegExp(
          /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        ).test(email);
      },
      isValidPw: (pw: string) => {
        // 최소 8자, 숫자, 특수문자 1개 이상 포함
        return new RegExp(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
        ).test(pw);
      },
      isSame: (target1: unknown, target2: unknown) => {
        return !!target1 && !!target2 && target1 === target2;
      },
    },
  };
}
