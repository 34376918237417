<template>
  <GoogleLogin class="login-btn google" :params="params" :onSuccess="onSuccess" :onFailure="onFailure" v-if="clientId">
    <img src="@/assets/img/login/google_logo.svg" alt="" />
    Google 계정으로 로그인
  </GoogleLogin>
</template>
<script>
import GoogleLogin from 'vue-google-login';
export default {
  name: 'googleLogin',
  props: ['clientId', 'ru', 'cu', 'querys'],
  data() {
    return {
      params: {
        client_id: '',
      },
    };
  },
  components: { GoogleLogin },
  methods: {
    hasRequiredParams() {
      let required_params = ['clientId', 'ru', 'cu'];
      let is_valid = true;
      required_params.some((param) => {
        if (!this[param]) {
          is_valid = false;
          return true;
        }
      });
      return is_valid;
    },
    googleLoginCallback(id_token) {
      // 필수 파라미터 검증
      if (!this.hasRequiredParams()) {
        alert('필수 파라미터가 누락되었습니다.');
        return;
      }

      // 파라미터 설정
      let param_obj = {
        googleIdToken: id_token,
        ru: this.ru,
        cu: this.cu,
      };

      // 구글 로그인 연동
      this.$axios
        .post('/user/login/googleSignInCallback.hs', null, {
          params: param_obj,
        })
        .then(function (response) {
          var result = response.data.result;

          if (result.REPL_CD === '000000') {
            window.location.href = result.redirectUrl;
          } else {
            alert(result.REPL_MSG);
          }
        });
    },
    async onSuccess(googleUser) {
      this.googleLoginCallback(googleUser.getAuthResponse().id_token);
    },
    onFailure(error) {
      console.log(error);
    },
  },
  watch: {
    clientId: function (newValue) {
      newValue && (this.params.client_id = newValue);
    },
  },
};
</script>
