import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios, { AxiosStatic } from "axios";
import VueCookies from "vue-cookies";

// 전역 Component 선언
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.use(VueCookies);

declare module "vue/types/vue" {
  interface Vue {
    $axios: AxiosStatic;
  }
}

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
