<template>
  <button class="login-btn linkedin" v-on:click="logInWithLinkedin">
    <img src="@/assets/img/login/linkedin_logo.svg" alt="" />
    Linkedin 계정으로 로그인
  </button>
</template>
<script>
export default {
  name: 'linkedinLogin',
  props: ['clientId', 'ru', 'cu', 'querys'],
  methods: {
    hasRequiredParams() {
      let required_params = ['clientId', 'ru', 'cu'];
      let is_valid = true;
      required_params.some((param) => {
        if (!this[param]) {
          is_valid = false;
          return true;
        }
      });
      return is_valid;
    },
    logInWithLinkedin() {
      // 필수 파라미터 검증
      if (!this.hasRequiredParams()) {
        alert('필수 파라미터가 누락되었습니다.');
        return;
      }

      this.$axios.post('/user/login/linkedinSignInCallbackForAuthorization.hs').then((response) => {
        let result = response.data.result;

        if (result.REPL_CD == '000000') {
          let winLeft = screen.width / 2 - 1000 / 2;
          let winTop = screen.height / 2 - 830 / 2;
          var dimensions = 'width=500,height=700,left=' + winLeft + ',top=' + winTop;

          let authwindow = window.open(result.requestUrl, '_blank', dimensions);
          authwindow.focus();
        } else {
          alert(result.REPL_MSG);
        }
      });
    },
    // 링크드인 콜백
    linkedinCallback(email, name, id) {
      if (!email || !id) {
        return false;
      }
      this.linkedinSignInCallbackByOauth(email, name, id);
    },
    // 링크드인 로그인 연동
    linkedinSignInCallbackByOauth(email, name, id) {
      let param_obj = {
        snsKey: id,
        snsEmail: email,
        snsName: name,
        ru: this.ru,
        cu: this.cu,
      };
      this.$axios
        .post('/user/login/linkedinSignInCallbackController.hs', null, {
          params: param_obj,
        })
        .then(function (response) {
          var result = response.data.result;

          if (result.REPL_CD == '000000') {
            window.location.href = result.redirectUrl;
          } else {
            alert(result.REPL_MSG);
          }
        });
    },
  },
  mounted() {
    // 링크드인 콜백
    window.hsLinkedinCallback = (email, name, id) => {
      this.linkedinCallback(email, name, id);
    };
  },
};
</script>
