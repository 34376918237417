import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import LoginForm from "../views/user/login/LoginForm.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // 상단에서 import 하고 URL 맵핑(라우팅) 하는 방식
  {
    path: "/user/login/loginForm.hv",
    name: "loginForm",
    component: LoginForm,
    meta: {
      title: "통합 로그인",
    },
  },
  // 상단에서 import 없이 URL 맵핑(라우팅) 영역에서 바로 import 하는 방식
  {
    path: "/user/join/joinForm.hv",
    name: "joinForm",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/join/JoinForm.vue"),
    meta: {
      title: "통합 회원가입",
    },
  },
  {
    path: "*",
    redirect: "/user/login/loginForm.hv",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 라우터 에러 발생 시
router.onError((error) => {
  console.log(error);
  if (error.name === "ChunkLoadError") {
    window.location.href = "/";
  }
});

// title 설정
router.afterEach((to: any, from: any) => {
  const title = to.meta.title === undefined ? "Patentpia" : to.meta.title;
  Vue.nextTick(() => {
    document.title = title;
  });
});

export default router;
