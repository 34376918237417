









































import { Component } from 'vue-property-decorator';
import CommonVue from '@/components/common/CommonVue.vue'; // 공통 Vue
import NaverLoginBtn from '@/components/user/social/NaverLoginBtn.vue'; // NaverLogin
import GoogleLoginBtn from '@/components/user/social/GoogleLoginBtn.vue'; // GoogleLogin
import LinkedinLoginBtn from '@/components/user/social/LinkedinLoginBtn.vue'; // LinkedinLogin

@Component({
  components: {
    GoogleLoginBtn,
    LinkedinLoginBtn,
    NaverLoginBtn,
  },
})
export default class LoginForm extends CommonVue {
  // 로그인 변수 설정
  IS_LOADING = false as boolean;
  USER_ID = '' as string;
  USER_PW = '' as string;
  RU = '' as string;
  CU = '' as string;
  GOOGLE_CLIENT_ID = '';
  FACEBOOK_APP_ID = '';
  LINKEDIN_CLIENT_ID = '';
  NAVER_URL = '';
  URL_QUERY = {};
  GOOGLE_LOGIN_PARAMS = {
    client_id: this.GOOGLE_CLIENT_ID,
  };

  // 마운티드 사이클
  mounted() {
    this.initLoginPage(); // 로그인 페이지 초기화 함수 호출
    this.getSocialLoginValues(); // 소셜로그인 제반정보 조회 호출
  }

  // 로그인 페이지 초기화 함수
  initLoginPage() {
    this.RU = this.COM.route.getQuery('ru');
    this.CU = this.COM.route.getQuery('cu');
    this.URL_QUERY = this.COM.route.get().query;
  }

  // 소셜로그인 제반정보 조회
  getSocialLoginValues(): void {
    this.COM.ajax.call('/user/login/getSocialLoginValues.hs').then((res) => {
      let result = res.data.result;
      if (result.REPL_CD === '000000') {
        this.GOOGLE_CLIENT_ID = result.googleClientId;
        this.FACEBOOK_APP_ID = result.facebookAppId;
        this.LINKEDIN_CLIENT_ID = result.linkedinClientId;
        this.NAVER_URL = result.naverUrl;
      } else {
        alert(result.REPL_MSG);
      }
    });
  }

  // 로그인 버튼 클릭
  clickLoginBtn(): void {
    this.validationLogin();
  }

  // 로그인 검증 AJAX
  validationLogin(): void {
    if (!this.USER_ID || !this.USER_PW) {
      alert('아이디 / 비밀번호를 입력해주세요.');
      return;
    }

    // parameter setting
    let param_obj = {
      userId: this.USER_ID,
      userPw: this.USER_PW,
    };

    this.COM.ajax.call('/user/login/validateLogin.hs', param_obj).then((res) => {
      let result = res.data.result;
      if (result.REPL_CD === '000000') {
        this.login();
      } else if (result.REPL_CD === '010103') {
        if (confirm(result.REPL_MSG)) {
          this.login();
        }
      } else {
        alert(result.REPL_MSG);
      }
    });
  }

  // 엔터키 검증
  keyupEnterToNext(nextElementRefName: string): void {
    this.COM.refs.focus(nextElementRefName);
  }

  // 로그인 AJAX
  login(): void {
    let param_obj = {
      userId: this.USER_ID,
      userPw: this.USER_PW,
      cu: this.COM.route.getQuery('cu'),
      ru: this.COM.route.getQuery('ru'),
    };

    this.COM.ajax.call('/user/login/login.hs', param_obj).then((res) => {
      let result = res.data.result;
      if (result.REPL_CD === '000000') {
        window.location.href = result.redirectUrl;
      } else {
        alert(result.REPL_MSG);
      }
    });
  }

  // 회원가입 버튼 클릭
  clickJoinBtn(): void {
    this.COM.router.get().push({ path: '/user/join/joinForm.hv', query: this.URL_QUERY });
  }
}
